<template>
  <div>
    <b-card title="Vous pouvez rechercher les paiements échoués (3x) directement ici.">
      <!-- <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher une commande..."
                style="height: auto;"
                class="border-0 shadow-none"
                @keyup.enter="setFilter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row> -->
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="orders"
          :fields="fields"
          responsive="sm"
          @row-selected="onRowSelected"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template>
          <template #cell(date_created)="data">
            <b-badge variant="light-danger">
              {{ $moment(data.item.date_created).format('DD-MM-YYYY') }}
            </b-badge>
          </template>
          <template #row-details="data">
            <b-card>
              <b-row class="mb-2">
                <b-col
                  v-if="data.item && data.item.billing"
                  md="4"
                  class="mb-1"
                >
                  <p><strong>Total:</strong> {{ data.item.total }}€</p>
                  <p><strong>Facturation:</strong></p>
                  <span>{{ data.item.billing.first_name }} {{ data.item.billing.last_name }}</span>
                  <br>
                  <span>{{ data.item.billing.address_1 }} {{ data.item.billing.address_2 }}</span>
                  <br>
                  <span>{{ data.item.billing.city }} {{ data.item.billing.postcode }} {{ data.item.billing.country }}</span>
                  <br>
                  <span>{{ data.item.billing.email }}</span>
                  <br>
                  <span>{{ data.item.billing.phone }}</span>
                </b-col>
                <b-col
                  v-if="data.item && data.item.shipping"
                  md="4"
                  class="mb-1"
                >
                  <p><strong>Livraison:</strong></p>
                  <span>{{ data.item.shipping.first_name }} {{ data.item.shipping.last_name }}</span>
                  <br>
                  <span>{{ data.item.shipping.address_1 }} {{ data.item.shipping.address_2 }}</span>
                  <br>
                  <span>{{ data.item.shipping.city }} {{ data.item.shipping.postcode }} {{ data.item.shipping.country }}</span>
                  <br>
                  <span>{{ data.item.customer_note }}</span>
                </b-col>
                <b-col
                  v-if="data.item && data.item.line_items"
                  md="4"
                  class="mb-1"
                >
                  <p><strong>Produits:</strong></p>
                  <div v-for="(p, key) of data.item.line_items" :key="key" class="mb-2">
                    <span>{{ products.find(pr => pr.idWoo === p.product_id).name }} x{{ p.quantity }}</span>
                    <br>
                    <span><strong>Id de la variation:</strong> {{ p.variation_id }}</span>
                  </div>
                  <p v-if="data.item.coupon_lines && data.item.coupon_lines.length"><strong>Code promo:</strong></p>
                  <div v-for="(c, keyC) of data.item.coupon_lines" :key="keyC" class="mb-2">
                    <span><strong>{{ c.code }}</strong></span>
                  </div>
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="data.toggleDetails"
              >
                Cacher les détails
              </b-button>
              <b-button
                class="ml-2"
                size="sm"
                variant="primary"
                @click="resolve(data.item.order.number)"
              >
                Marqué comme résolu
              </b-button>
            </b-card>
          </template>

          <!-- <template #cell(code)="data">
            <p class="font-weight-bolder">{{ data.item.code }}</p>
          </template>

          <template #cell(usage_count)="data">
            <b-progress
              v-if="data.item.usage_limit"
              v-b-popover.hover.top="`${data.item.usage_count}/${data.item.usage_limit}`"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :value="Number(data.item.usage_count)"
              :max="Number(data.item.usage_limit)"
              :variant="getVariant(data.item)"
              striped
            />
            <span v-else>Illimité</span>
          </template>

          <template #cell(individual_use)="data">
            <b-badge :variant="`light-${data.item.individual_use ? 'success' : 'danger'}`">
              {{ data.item.individual_use ? 'Oui' : 'Non' }}
            </b-badge>
          </template> -->

          <!-- <template #cell(discount_type)="data">
            <b-badge :variant="`light-${data.item.discount_type === 'fixed_cart' ? 'info' : data.item.discount_type === 'percent' ? 'warning' : 'success'}`">
              {{ data.item.amount }}{{ data.item.discount_type === 'fixed_cart' ? '€' : data.item.discount_type === 'percent' ? '%' : data.item.discount_type }}
            </b-badge>
          </template> -->

          <template #cell(code)="data">
            <p v-if="data.item && data.item.coupon_lines && data.item.coupon_lines.length">{{ data.item.coupon_lines.map(c => c.code).join(' / ') }}</p>
          </template>
          <!-- <template #cell(status)="data">
            {{ data }}
          </template> -->
        </b-table>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="itemsPerPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="getOrders"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  // BFormInput,
  BTable,
  BPagination,
  VBPopover,
  BFormCheckbox,
  BBadge,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BButton,
    BPagination,
    BFormCheckbox,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseOrders: [],
      orders: [],
      products: [],
      categories: [],
      coupon: {},
      code: '',
      fields: [{ key: 'show_details', label: 'Détails' }, { key: 'date_created', label: 'Date de création' }, { key: 'number', label: 'Commande' }, { key: 'billing.first_name', label: 'Prénom' }, { key: 'billing.last_name', label: 'Nom' }, { key: 'billing.email', label: 'Email' }, { key: 'billing.phone', label: 'Téléphone' }],
      showModal: false,
      categoryInformations: [],
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT'],
      currentPage: 10,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 25,
      loading: false,
    }
  },
  watch: {
    country: {
      deep: true,
      handler: 'getOrders',
    },
  },
  async mounted() {
    try {
      await this.getOrders()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getOrders() {
      try {
        const { orders } = await this.$http.get('/admin/failed-paiement')
        this.orders = orders
        this.baseOrders = orders
        const { products } = await this.$http.get(`/admin/blog/list-product/?country=${this.country}`)
        this.products = products
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    setFilter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.orders = this.baseOrders
      } else {
        const value = this.code
        this.orders = this.baseOrders.filter(log => log.billing?.first_name?.toLowerCase().includes(value.toLowerCase()) || log.billing?.last_name?.toLowerCase().includes(value.toLowerCase()) || log.billing?.email?.toLowerCase().includes(value.toLowerCase()))
      }
    },
    async resolve(id) {
      try {
        await this.$http.post(`/admin/failed-paiement/${id}`)
      } catch (err) {
        console.log(err)
      }
    },
    getVariant(item) {
      const variant = `${Number(item.usage_count) < Number(item.usage_limit) ? 'success' : 'danger'}`
      return variant
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
